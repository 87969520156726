import React,{Suspense,lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// Pages


 const AdminLogin = lazy(() => import('../pages/admin/login'));
// import Dashboard from "../pages/admin/Dashboard";
// import NonBlockIp from "../pages/admin/NonBlockIp";
const Collections = lazy(() => import('../pages/admin/collection/Collections'));
//import Collections from "../pages/admin/collection/Collections";
//import CollectionsAdd from "../pages/admin/collection/CollectionsAdd";
const CollectionsAdd = lazy(() => import('../pages/admin/collection/CollectionsAdd'));
//import CollectionsEdit from "../pages/admin/collection/CollectionsEdit";
const CollectionsEdit = lazy(() => import('../pages/admin/collection/CollectionsEdit'));
const CollectionsBrowse = lazy(() => import('../pages/admin/collection/CollectionsBrowse'));
//import CollectionsShare from "../pages/admin/collection/CollectionsShare";
const CollectionsShare = lazy(() => import('../pages/admin/collection/CollectionsShare'));
const CollectionsSendMail = lazy(() => import('../pages/admin/collection/CollectionsSendMail'));
//import AdminBusinessPartner from "../pages/admin/businessPartner/AdminBusinessPartner";
const AdminBusinessPartner = lazy(() => import('../pages/admin/businessPartner/AdminBusinessPartner'));
//import AdminBusinessPartnerAdd from "../pages/admin/businessPartner/AdminBusinessPartnerAdd";
const AdminBusinessPartnerAdd = lazy(() => import('../pages/admin/businessPartner/AdminBusinessPartnerAdd'));
//import AdminBusinessPartnerEdit from "../pages/admin/businessPartner/AdminBusinessPartnerEdit";
const AdminBusinessPartnerEdit = lazy(() => import('../pages/admin/businessPartner/AdminBusinessPartnerEdit'));
//import AdminProductList from "../pages/admin/products/ProductList";
const AdminProductList = lazy(() => import('../pages/admin/products/ProductList'));
//import AdminProductAdd from "../pages/admin/products/AdminProductAdd";
const AdminProductAdd = lazy(() => import('../pages/admin/products/AdminProductAdd'));
//import AdminProductEdit from "../pages/admin/products/AdminProductEdit";
const AdminProductEdit = lazy(() => import('../pages/admin/products/AdminProductEdit'));
const AdminProductDuplicate = lazy(() => import('../pages/admin/products/AdminProductDuplicate'));
const AdminProductShare = lazy(() => import('../pages/admin/products/AdminProductShare'));
const AdminProductEmail = lazy(() => import('../pages/admin/products/AdminProductEmail'));
/////banners
//import AdminBanner from "../pages/admin/banners/AdminBannerListComponent";
const AdminBanner = lazy(() => import('../pages/admin/banners/AdminBannerListComponent'));
//import AdminBannerAdd from "../pages/admin/banners/AdminBannerAddComponent";
const AdminBannerAdd = lazy(() => import('../pages/admin/banners/AdminBannerAddComponent'));
//import AdminBannerEdit from "../pages/admin/banners/AdminBannerEditComponent";
const AdminBannerEdit = lazy(() => import('../pages/admin/banners/AdminBannerEditComponent'));
/////loginimages
//import AdminLoginimages from "../pages/admin/loginimages/AdminLoginimagesListComponent";
const AdminLoginimages = lazy(() => import('../pages/admin/loginimages/AdminLoginimagesListComponent'));
//import AdminLoginimagesAdd from "../pages/admin/loginimages/AdminLoginimagesAddComponent";
const AdminLoginimagesAdd = lazy(() => import('../pages/admin/loginimages/AdminLoginimagesAddComponent'));
//import AdminLoginimagesEdit from "../pages/admin/loginimages/AdminLoginimagesEditComponent";
const AdminLoginimagesEdit = lazy(() => import('../pages/admin/loginimages/AdminLoginimagesEditComponent'));
/////Category
//import AdminCategory from "../pages/admin/category/AdminListComponent";
const AdminCategory = lazy(() => import('../pages/admin/category/AdminListComponent'));
//import AdminCategoryAdd from "../pages/admin/category/AdminAddComponent";
const AdminCategoryAdd = lazy(() => import('../pages/admin/category/AdminAddComponent'));
//import AdminCategoryEdit from "../pages/admin/category/AdminEditComponent";
const AdminCategoryEdit = lazy(() => import('../pages/admin/category/AdminEditComponent'));
//////Subcategory

///Fair and Events
//import AdminFairEvents from "../pages/admin/fair-events/FairEvents";
const AdminFairEvents = lazy(() => import('../pages/admin/fair-events/FairEvents'));
//import AdminFairEventsAdd from "../pages/admin/fair-events/AdminFairEventsAdd";
const AdminFairEventsAdd = lazy(() => import('../pages/admin/fair-events/AdminFairEventsAdd'));
//import AdminFairEventsEdit from "../pages/admin/fair-events/AdminFairEventsEdit";
const AdminFairEventsEdit = lazy(() => import('../pages/admin/fair-events/AdminFairEventsEdit'));
/// Document
const Document = lazy(() => import('../pages/admin/document/Document'));
const DocumentAdd = lazy(() => import('../pages/admin/document/DocumentAdd'));
const DocumentEdit = lazy(() => import('../pages/admin/document/DocumentEdit'));
const DocumentShare = lazy(() => import('../pages/admin/document/DocumentShare'));
const DocumentSendMail = lazy(() => import('../pages/admin/document/DocumentSendMail'));

const Adminopportunities = lazy(() => import('../pages/admin/opportunities/Adminopportunities'));
const AdminopportunitiesView = lazy(() => import('../pages/admin/opportunities/AdminopportunitiesView'));
// import AddProduct from "../pages/admin/AddProduct";
//import Social from "../pages/admin/Social";
const Social = lazy(() => import('../pages/admin/Social'));


//import AuthRoute from "./AuthRoute";
const AuthRoute = lazy(() => import('./AuthRoute'));
//Front End
///Front End
//import SiteComponent from "./siteComponent";
const SiteComponent = lazy(() => import('./siteComponent'));
const HomeComponent = lazy(() => import('../pages/user/HomeComponent'));
const CategoryComponent = lazy(() => import('../pages/user/CategoryComponent'));
const ProductsComponent = lazy(() => import('../pages/user/ProductsComponent'));
const ProductDetailComponent = lazy(() => import('../pages/user/ProductDetailComponent'));
const AboutUsComponent = lazy(() => import('../pages/user/AboutUsComponent'));
const FairEventssComponent = lazy(() => import('../pages/user/FairEventssComponent'));
const ContactUsComponent = lazy(() => import('../pages/user/ContactUsComponent'));
const WishlistComponent = lazy(() => import('../pages/user/WishlistComponent'));
const SearchProductsComponent = lazy(() => import('../pages/user/SearchProductsComponent'));
const ProductShareComponent = lazy(() => import('../pages/user/ProductShareComponent'));
const CollectionDetailComponent = lazy(() => import('../pages/user/CollectionDetailComponent'));
const CollectionGalleryComponent = lazy(() => import('../pages/user/CollectionGalleryComponent'));
const ProductGalleryComponent = lazy(() => import('../pages/user/ProductGalleryComponent'));
const DownloadeqnuiryComponent = lazy(() => import('../pages/user/DownloadeqnuiryComponent'));
const DownloadcollectionComponent = lazy(() => import('../pages/user/DownloadcollectionComponent'));
const DocumentetailComponent = lazy(() => import('../pages/user/DocumentetailComponent'));
const ThankyouComponent = lazy(() => import('../pages/user/ThankyouComponent'));
const LoginComponent= lazy(() => import('../pages/user/LoginComponent'));
const RegisterComponent= lazy(() => import('../pages/user/RegisterComponent')); 
const MaintenceComponent= lazy(() => import('../pages/user/MaintenceComponent')); 

const Navigator = () => {
  

  return (
    <Router>
       <Suspense fallback={<div id="ec-overlay"><span className="loader_img"></span></div>}>
     
      <Switch>
     
       
         <Route exact path="/downloadeqnuiry/:id" component={DownloadeqnuiryComponent} />
         <Route exact path="/downloadcollection/:id" component={DownloadcollectionComponent} />
        <Route exact path="/admin" component={AdminLogin} />     
        <AuthRoute exact path="/admin/collections" component={Collections} />
        <AuthRoute exact path="/admin/collections/add" component={CollectionsAdd} />
        <AuthRoute exact path="/admin/collections/edit/:id" component={CollectionsEdit} />
        <AuthRoute exact path="/admin/collections/browse/:id" component={CollectionsBrowse} />
        <AuthRoute exact path="/admin/collections/share/:id" component={CollectionsShare} />
        <AuthRoute exact path="/admin/collections/sendmail/:id" component={CollectionsSendMail} />
        <AuthRoute exact path="/admin/business-partner" component={AdminBusinessPartner} />
        <AuthRoute exact path="/admin/business-partner/add" component={AdminBusinessPartnerAdd} />
        <AuthRoute exact path="/admin/business-partner/edit/:id" component={AdminBusinessPartnerEdit} />
        <AuthRoute exact path="/admin/product-list" component={AdminProductList} />
        <AuthRoute exact path="/admin/product-list/add" component={AdminProductAdd} />
        <AuthRoute exact path="/admin/product-list/edit/:id" component={AdminProductEdit} />
        <AuthRoute exact path="/admin/product-list/duplicate/:id" component={AdminProductDuplicate} />
        <AuthRoute exact path="/admin/product-list/share/:id" component={AdminProductShare} />
        <AuthRoute exact path="/admin/product-list/email/:id" component={AdminProductEmail} />

        <AuthRoute exact path="/admin/banners" component={AdminBanner} />
        <AuthRoute exact path="/admin/banners/add" component={AdminBannerAdd} />
        <AuthRoute exact path="/admin/banners/edit/:id" component={AdminBannerEdit} />

        <AuthRoute exact path="/admin/loginimages" component={AdminLoginimages} />
        <AuthRoute exact path="/admin/loginimages/add" component={AdminLoginimagesAdd} />
        <AuthRoute exact path="/admin/loginimages/edit/:id" component={AdminLoginimagesEdit} />

        <AuthRoute exact path="/admin/category" component={AdminCategory} />
        <AuthRoute exact path="/admin/category/add" component={AdminCategoryAdd} />
        <AuthRoute exact path="/admin/category/edit/:id" component={AdminCategoryEdit} />
      
        <AuthRoute exact path="/admin/fair-events" component={AdminFairEvents} />
        <AuthRoute exact path="/admin/fair-events/add" component={AdminFairEventsAdd} />
        <AuthRoute exact path="/admin/fair-events/edit/:id" component={AdminFairEventsEdit} /> 
        <AuthRoute exact path="/admin/opportunities" component={Adminopportunities} />
        <AuthRoute exact path="/admin/opportunities/views/:id" component={AdminopportunitiesView} />
        <AuthRoute exact path="/admin/document" component={Document} />
        <AuthRoute exact path="/admin/document/add" component={DocumentAdd} />
        <AuthRoute exact path="/admin/document/edit/:id" component={DocumentEdit} />
        <AuthRoute exact path="/admin/document/share/:id" component={DocumentShare} />
        <AuthRoute exact path="/admin/document/sendmail/:id" component={DocumentSendMail} />
        <AuthRoute exact path="/admin/social" component={Social} />
        <Route exact path="/admin/catalog" component={Social} />
        <AuthRoute exact path="/admin/changePassword" component={Social} />  
           
         <SiteComponent>
         <Route exact path="/login" component={LoginComponent} />
         <Route exact path="/register" component={RegisterComponent} />
         <Route exact path="/about-us" component={AboutUsComponent} />
         <Route exact path="/thankyou" component={ThankyouComponent} />
         <Route exact path="/contact-us" component={ContactUsComponent} />
         <Route exact path="/wishlist" component={WishlistComponent} />
         <Route exact path="/collectiondetail/:id/:userId" component={CollectionDetailComponent} />
         <Route exact path="/documentetail/:id/:userId" component={DocumentetailComponent} />
         <Route exact path="/productshare/:id/:userId" component={ProductShareComponent} />
         <Route exact path="/collectiongallery/:id/:name" component={CollectionGalleryComponent} />
         <Route exact path="/productgallery/:id" component={ProductGalleryComponent} />
         <Route exact path="/fair-events" component={FairEventssComponent} />
        <Route exact path="/all-category" component={CategoryComponent} />
        <Route exact path="/products/:cat" component={ProductsComponent} />
        <Route exact path="/productdetail/:itemNumber" component={ProductDetailComponent} />  
        <Route exact path="/search-products/:searchItem" component={SearchProductsComponent} />             
        <Route exact path="/home" component={HomeComponent} />
        <Route exact path="/" component={MaintenceComponent} />
      </SiteComponent>  
        <Redirect to="/not-found" />
      </Switch>
    
      </Suspense>
    </Router>
  );
};

export default Navigator;
